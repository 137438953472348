/*! ----NETPROFIT---- */
@import "_lib/scss-mixins/mixins.scss"; // Import mixins
// Overwrite Global Variables here

@import "variables.scss"; // Import Variables


/*! --Plugins & Libraries-- */
@import "_lib/typography/main.scss"; // Import Typography Lib after settings


/*
---
name: Gridlex
category: SCSS Libraries
---
Wir benutzen zum layouten das auf flexbox basierende Grid-System Gridlex.

**Beispiel:**
```sample:/styleguide/assets/gridlex-demo.html
```
Nutzung und Möglichkeiten lassen sich in der [Gridlex Dokumentation](http://gridlex.devlint.fr/) nachlesen.

[Dokumentation zu den SCSS Variablen](http://gridlex.devlint.fr/#sass-documentation)

Falls kein Grid-System benötigt wird, einfach auskommentieren.
*/
/*
$gl-colCount: 12;
$gl-gutter: 1rem;
$gl-gutter-vertical: 1rem;
$gl-mq-width: 'max-width';
$gl-mq-list: (
	lg: $desktop,
	md: $tablet, 
	sm: $tablet_s, 
	xs: $mobile 
);
@import "gridlex/src/gridlex.scss";
*/

/*! --Fonts-- */
/*
---
name: Mono Icons
category: SCSS Libraries
---
Wir benutzen [Mono Icons](https://icons.mono.company/).

**Nutzung:** Dem Element, in dem das Icon angezeigt werden soll, die Klasse `.mi-<icon-name-here>` geben

Falls Kein Icon Font benötigt wird, einfach auskommentieren.

```icons:/styleguide/assets/icons.html
```
*/
@import "_lib/mono-icons.scss";



/*! ---STANDARD--- */
@import "_lib/modern-normalize.scss"; // CSS Reset

/*! ---CUSTOM--- */
@import "/Users/johannesblochl/Desktop/dev/dailyweight/src/scss/2_base/0_font.scss";
@import "/Users/johannesblochl/Desktop/dev/dailyweight/src/scss/2_base/1_typography.scss";
@import "/Users/johannesblochl/Desktop/dev/dailyweight/src/scss/2_base/2_layout.scss";
 // Base of the Theme

/*! --Elements-- */
@import "/Users/johannesblochl/Desktop/dev/dailyweight/src/scss/3_elements/footer.scss";
@import "/Users/johannesblochl/Desktop/dev/dailyweight/src/scss/3_elements/header.scss";
@import "/Users/johannesblochl/Desktop/dev/dailyweight/src/scss/3_elements/newWeight.scss";
@import "/Users/johannesblochl/Desktop/dev/dailyweight/src/scss/3_elements/userdata.scss";
@import "/Users/johannesblochl/Desktop/dev/dailyweight/src/scss/3_elements/weighlist.scss";
 // Component Styling

@import "/Users/johannesblochl/Desktop/dev/dailyweight/src/scss/4_last/0_breakpoints.scss";
@import "/Users/johannesblochl/Desktop/dev/dailyweight/src/scss/4_last/1_print.scss";
 // Media Queries
