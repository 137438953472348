@font-face {
    font-family: 'Quicksand';
    src: url('/assets/font/Quicksand-Light.ttf');
    font-weight: 300;
    font-display: swap;
}

@font-face {
    font-family: 'Quicksand';
    src: url('/assets/font/Quicksand-Regular.ttf');
    font-weight: 400;
    font-display: swap;
}

@font-face {
    font-family: 'Quicksand';
    src: url('/assets/font/Quicksand-Bold.ttf');
    font-weight: 600;
    font-display: swap;
}
