main {
    .weightlist {
        overflow-y: scroll;
        height: 77;
        &::-webkit-scrollbar {
            display: none;
        }
        .weightItem {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            padding: 1rem 2rem;
            border-bottom: 1px solid gray;
            position: relative;
            .deleteButton {
                position: absolute;
                right: .5rem;
                top: calc(50% - -7.5px);
                width: 15px;
                height: 15px;
                background-image: url(/assets/images/delete.png);
                background-size: cover;
                &:hover {
                    cursor: pointer;
                }
            }
            p {
                margin: 0;
            }
            .left {
                display: flex;
                flex-direction: column;
                gap: .5rem;
            }
            .right {
                display: flex;
                align-items: end;
                p {
                    font-weight: 600;
                    font-size: 1.2rem;
                }
            }
        }
    }
}