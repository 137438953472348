header {
    background-color: $primary;
    position: sticky;
    top: 0;
    left: 0;
    width: 100%;
    height: fit-content;
    padding: 2.5rem 0rem;
    border-bottom-left-radius: 50px;
    border-bottom-right-radius: 50px;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;

    .counterbox {
        position: absolute;
        top: 1rem;
        right: 1rem;
        color: $white;
        p {
            margin: 0;
        }
    }
    .weightbox {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        p {
            margin: 0;
            color: $white;
        }
        .actWeight {
            font-weight: 600;
            font-size: 1.75rem;
            line-height: 3rem;
        }
    }
    .addWeight {
        position: absolute;
        right: 1rem;
        bottom: 2rem;
        width: 20px;
        height: 20px;
        &:hover {
            cursor: pointer;
        }
    }
}