.userdata {
    display: none;
    justify-content: center;
    align-items: center;
    position: fixed;
    bottom: 0;
    height: 50vh;
    width: 100%;
    max-width: 400px;
	margin: 0 auto;
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
    background-color: $primary;
    &.active {
        display: flex;
    }
    .inputdata {
        display: flex;
        flex-direction: column;
        gap: .5rem;
        input {
            width: 100%;
            padding: .5rem 2rem;
            border-radius: 20px;
            border: unset;
            text-align: center;
        }
        .button {
            padding: .5rem 2rem;
            text-transform: uppercase;
            text-align: center;
            background-color: $white;
            color: $primary;
            border-radius: 20px;
            &:hover {
                cursor: pointer;
            }
        }
    }
}