.newWeightContainer {
    display: none;
    justify-content: center;
    align-items: center;
    position: fixed;
    bottom: 0;
    height: 50vh;
    width: 100%;
    max-width: 400px;
	margin: 0 auto;
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
    background-color: $primary;
    &.active {
        display: flex;
    }
    .newWeight-inner {
        display: flex;
        flex-direction: column;
        gap: .5rem;
        input {
            width: 100%;
            padding: .5rem 2rem;
            border-radius: 20px;
            border: unset;
            text-align: center;
            outline: none;

        }
        input[type=date]{
            -webkit-appearance: none;
            color: $black;
            height: fit-content;
            min-height: 30px;
            background-color: $white;
        }
        .newDate {
            position: relative;
            width: 100%;
            padding: 10px;
        }

        .placeholder {
            position: absolute;
            left: 10px;
            top: -2rem;
            pointer-events: none;
            color: white;
            font-size: 16px;
        }

       
        .button {
            padding: .5rem 2rem;
            text-transform: uppercase;
            text-align: center;
            background-color: $white;
            color: $primary;
            border-radius: 20px;
            &:hover {
                cursor: pointer;
            }
        }
    }
}