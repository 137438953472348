/*! --Layout-- */
body {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	min-height: 100vh;
	max-width: 400px;
	margin: 0 auto;
}
#footer {
	margin-top: auto;
}
img {
	display: block;
	max-width: 100%;
	height: auto;
	width: auto;
}